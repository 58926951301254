<template>
    <div class="checkoutPax">
        <div class="checkout-resume-row">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestFirstName" ref="guestFirstName" type="text" placeholder="Nome del viaggiatore principale" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestFirstName')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Inserisci il nome del viaggiatore di riferimento, che sarà presente al checkin'"/>
            </div>
            <!--
                <CustomInput
                    size="sm"
                    type="text"
                    ref="guestFirstName"
                    validate="firstName"
                    placeholder="Nome del viaggiatore principale"
                    popover="Inserisci il nome del viaggiatore di riferimento, che sarà presente al checkin"
                    :default="guestFirstName"
                />
            -->
        </div>
        <div class="checkout-resume-row">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestLastName" ref="guestLastName" type="text" placeholder="Cognome del viaggiatore principale" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestLastName')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Inserisci il cognome del viaggiatore di riferimento, che sarà presente al checkin'"/>
            </div>
        </div>
        <div class="checkout-resume-row">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestEmail" ref="guestEmail" type="email" placeholder="Email del viaggiatore" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestEmail')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Il voucher per fare check-in in hotel verrà inviato a questo indirizzo email'" />
            </div>
        </div>
        <div class="checkout-resume-row">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestEmailConf" ref="guestEmailConf" type="email" placeholder="Conferma email del viaggiatore" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestEmailConf')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Te la facciamo riscrivere per scoprire eventuali errori di digitazione'" />
            </div>
        </div>
        <div class="checkout-resume-row">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestPhone" ref="guestPhone" type="tel" placeholder="Numero di cellulare del viaggiatore" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestPhone')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Avere il tuo numero ci permetterà di contattarti velocemente in caso di qualsiasi problema'" />
            </div>
        </div>
    </div>
</template>

<script>
import SvgInfoCircle from './../../svg/info-circle'

export default {
    name: 'checkoutPax',
    components:
    {
        SvgInfoCircle,
    },
    props:
    {
        inputSize: String
    },
    mounted()
    {

        let sessionData = this.$session.get("sidebarBlocks")

        if(sessionData !== undefined)
        {
            this.guestFirstName = sessionData.checkoutPax.fields.guestFirstName
            this.guestLastName = sessionData.checkoutPax.fields.guestLastName
            this.guestEmail = sessionData.checkoutPax.fields.guestEmail
            this.guestEmailConf = sessionData.checkoutPax.fields.guestEmailConf
            this.guestPhone = sessionData.checkoutPax.fields.guestPhone
        }

        this.validateBlock()
    },
    data()
    {
        return {
            guestFirstName: (process.env.VUE_APP_MODE == 'development')? 'Pinco' : '',
            guestLastName: (process.env.VUE_APP_MODE == 'development')? 'Pallo' : '',
            guestEmail: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            guestEmailConf: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            guestPhone: (process.env.VUE_APP_MODE == 'development')? '3934288350' : '',
        }
    },
    computed:
    {
    },
    methods:
    {
        clearField(ref)
        {
            this[ref] = ''
            this.$refs[ref].focus()
            this.validateBlock()
        },
        validateBlock()
        {
            let valid = this.mixinValidateFields([
                {
                    ref: this.$refs['guestFirstName'],
                    type: 'firstName',
                    value: this.guestFirstName,
                },
                {
                    ref: this.$refs['guestLastName'],
                    type: 'lastName',
                    value: this.guestLastName,
                },
                {
                    ref: this.$refs['guestEmail'],
                    type: 'email',
                    value: this.guestEmail,
                },
                {
                    ref: this.$refs['guestEmailConf'],
                    type: 'email',
                    value: this.guestEmailConf,
                },
                {
                    ref: this.$refs['guestEmailConf'],
                    type: 'emailConfirm',
                    value: (this.guestEmailConf == this.guestEmail),
                },
                {
                    ref: this.$refs['guestPhone'],
                    type: 'phone',
                    value: this.guestPhone,
                },
            ])

            this.$emit('sidebarBlockChanged',{
                name: 'checkoutPax',
                status: valid,
                fields: {
                    'guestFirstName': this.guestFirstName,
                    'guestLastName': this.guestLastName,
                    'guestEmail': this.guestEmail,
                    'guestEmailConf': this.guestEmailConf,
                    'guestPhone': this.guestPhone,
                }
            })

            return valid
        },
    }
}
</script>

<style lang="scss">
    
    
    

    .checkoutPax
    {
    }
</style>